import { Localization } from "./config";

export { Localization } from "./config";
export const IDLE_TIMEOUT = 1800;

export const MANDATORY_FIELD_ERROR_MESSAGE = Localization.isUsingMotoraceConfig
  ? "Please fill mandatory fields"
  : "Please Enter Input";

export const ERROR_MESSAGE = "Required Field";
export const INVALID_MOBNUM_ERROR = "Invalid Number";
export const MUST_START_WITH_9_MOBNUM_ERROR = "Phone Number must start with 9";
export const MODULE_CONFIG = [
  {
    moduleName: "IMD",
    image: `${process.env.PUBLIC_URL}/images/mftImg.png`,
    label: "Leads Management",
  },
  {
    moduleName: "AIDC",
    image: `${process.env.PUBLIC_URL}/images/aidcImg.png`,
    label: "Collections",
  },
];

// Role Type
export const ROLE_TYPE = {
  MANAGER: "Manager",
  SALES_PERSON: "Sales Person",
  CREDIT_INVESTIGATOR: "Credit investigator",
};

// Role Name
export const ROLE_NAME = {
  BRANCH_CONTROLLER: "Branch Controller",
  BRANCH_MANAGER: "Branch Manager",
  AREA_SUPERVISOR: "Area Supervisor",
  AREA_SALES_LEADER: "Area Sales Leader",
  AREA_SALES_MANAGER: "Area Sales Manager",
  AREA_MANAGER: "Area Manager",
  REGION_MANAGER: "Region Manager", // motorace
  REGIONAL_MANAGER: "Regional Manager", // wheeltek
  OPERATION_MANAGER: "Operation Manager",
  NATIONAL_MANAGER: "National Manager",
  SALES_PERSON: Localization.isUsingMotoraceConfig
    ? "Receptionist"
    : "Sales Person",
  COLLECTOR: Localization.isUsingMotoraceConfig
    ? "Credit and Sales Representative"
    : "Collector",
  BOOK_KEEPER: "Book Keeper", // wheeltek only
  CREDIT_BUREAU_HEAD: "Credit Bureau Head", // wheeltek only
  CCS: "CCS", // wheeltek only
  REGION_SALES_LEADER: "Region Sales Leader", // wheeltek only
  COLLECTOR_HEAD: "Collector Head", // wheeltek only
  SALES_AND_MARKETING_HEAD: "Sales and Marketing Head", // wheeltek only
  ADMINISTRATOR: "Administrator", // wheeltek only
  SUPER_USER: "Super User", // wheeltek only
  CREDIT_INVESTIGATOR: "Credit Investigator", // wheeltek only
  CREDIT_BUREAU_ANALYST: "Credit Bureau Analyst", // wheeltek only
  EVALUATOR: "Evaluator", // wheeltek only
  SALES_MANAGER: "Sales Manager",
};

// Loan Statuses
export const LOAN_STATUS = {
  PENDING_SUBMISSION: "Pending Submission",
  UNDER_VERIFICATION: "Under Verification", // after sales person send to book keeper
  CREDIT_REVIEW: "Credit Review", // after book keeper approve (send to bureau credit)
  PENDING_INVESTIGATION: "Pending Investigation", // bureau credit approve (send to credit investigator)
  PENDING_REVIEW: "Pending Review", // after credit investigator approve (send to book keeper)
  UNDER_EVALUATION: "Under Evaluation", // after final book keeper approve (send to evaluator)
  MANAGER_REVIEW: "Manager Review", // after evaluator approve (send to branch manager)
  AREA_MANAGER_REVIEW: "Area Manager Review", // on New Nemar
  FINANCE_MANAGER_REVIEW: "Finance Manager Review", // on Celestina
  APPROVED: "Approved",
  REJECTED: "Rejected",
  WITHDRAWN: "Withdrawn",
  DISBURSED: "Disbursed",
  CONDITIONAL_APPROVAL: "Conditional Approval",
  DISAPPROVED:"Disapproved"
};

export const DISABLE_EDIT_FORM_LOAN_STATUSES_WHEELTEK = [
	LOAN_STATUS.UNDER_VERIFICATION,
	LOAN_STATUS.CREDIT_REVIEW,
  LOAN_STATUS.PENDING_REVIEW,
	LOAN_STATUS.UNDER_EVALUATION,
	LOAN_STATUS.MANAGER_REVIEW,
	LOAN_STATUS.WITHDRAWN,
	LOAN_STATUS.DISBURSED,
];

export const DISABLE_EDIT_FORM_LOAN_STATUSES_NEW_NEMAR = [
	LOAN_STATUS.WITHDRAWN,
	LOAN_STATUS.DISBURSED,
	LOAN_STATUS.CREDIT_REVIEW,
	LOAN_STATUS.UNDER_EVALUATION,
	LOAN_STATUS.MANAGER_REVIEW,
];

export const DISABLE_EDIT_FORM_LOAN_STATUSES_CELESTINA = [
	LOAN_STATUS.PENDING_REVIEW,
	LOAN_STATUS.FINANCE_MANAGER_REVIEW,
	LOAN_STATUS.WITHDRAWN,
	LOAN_STATUS.DISBURSED,
];

export const DISABLE_EDIT_FORM_LOAN_STATUSES =
	Localization.isUsingNewNemarConfig
		? DISABLE_EDIT_FORM_LOAN_STATUSES_NEW_NEMAR
		: Localization.isUsingWheeltekConfig
		? DISABLE_EDIT_FORM_LOAN_STATUSES_WHEELTEK
		: Localization.isUsingCelestinaConfig
		? DISABLE_EDIT_FORM_LOAN_STATUSES_CELESTINA
		: [LOAN_STATUS.WITHDRAWN, LOAN_STATUS.DISBURSED];

export const DISABLE_EDIT_FORM_ROLES = [
  ROLE_NAME.BOOK_KEEPER,
  ROLE_NAME.CREDIT_BUREAU_ANALYST,
  ROLE_NAME.CREDIT_BUREAU_HEAD,
  ROLE_NAME.AREA_SUPERVISOR,
  ROLE_NAME.AREA_SALES_LEADER,
  ROLE_NAME.AREA_MANAGER,
];

export const MODULE_PERMISSION = [
  {
    roleName: ROLE_NAME.OPERATION_MANAGER,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.NATIONAL_MANAGER,
    modules: ["AIDC"],
  },
  {
    roleName: ROLE_NAME.REGION_MANAGER,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.REGIONAL_MANAGER,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.AREA_SUPERVISOR,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.AREA_SALES_LEADER,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.AREA_MANAGER,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.BRANCH_MANAGER,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.BRANCH_CONTROLLER,
    modules: ["IMD"],
  },
  {
    roleName: ROLE_NAME.COLLECTOR,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.BOOK_KEEPER,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.CREDIT_BUREAU_ANALYST,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.CREDIT_BUREAU_HEAD,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.CREDIT_INVESTIGATOR,
    modules: ["AIDC"],
  },
  {
    roleName: ROLE_NAME.CCS,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.REGION_SALES_LEADER,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.COLLECTOR_HEAD,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.SALES_AND_MARKETING_HEAD,
    modules: ["AIDC", "IMD"],
  },
  {
    roleName: ROLE_NAME.SUPER_USER,
    modules: ["AIDC", "IMD"],
  },
];

export const TYPES_OF_APPLICATION = [
  "First Time",
  "Repeat Customer",
  "Repo History in WMC",
  "Active Accounts",
  "Fully Paid in Other Dealer",
  "Repo in Other Dealer",
  "Active Accounts From Dealer",
  "Others",
];

export const PURPOSE_OF_PURCHASE = [
  { name: "Personal Service" },
  { name: "Business" },
  { name: "Leisure" },
  { name: "Donation/Gift" },
];
